<template>
  <div>
    <b-col class="p-0">
      <div
        class="scheduling-item d-flex justify-content-between align-items-center"
        :class="[isLinked ? 'scheduling-linked' : 'scheduling-list']"
      >
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.NameField') }}
          </span>
          <span class="text-item">
            {{ data.event }}
          </span>
        </div>
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.StartDate') }}
          </span>
          <span class="text-item">
            {{ formatDateAndHour(data.startDate) }}
          </span>
        </div>
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.RecurrenceEvery') }}
          </span>
          <span class="text-item">
            {{
              `
                            ${data.recurrenceNumber}
                            ${(data.recurrencePeriod == 0
                              ? this.$t('IntegrationPage.Seconds')
                              : data.recurrencePeriod == 1
                              ? this.$t('IntegrationPage.Minutes')
                              : data.recurrencePeriod == 2
                              ? this.$t('IntegrationPage.Hours')
                              : data.recurrencePeriod == 3
                              ? this.$t('IntegrationPage.Days2')
                              : data.recurrencePeriod == 4
                              ? this.$t('IntegrationPage.Weeks')
                              : data.recurrencePeriod == 5
                              ? this.$t('IntegrationPage.Months')
                              : ''
                            ).slice(0, -1)}(s)
                           `
            }}
          </span>
        </div>
        <div class="text-left d-flex flex-column item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.AdvancedOptions') }}
          </span>
          <span class="text-item">
            {{
              data.days.length > 0
                ? data.days
                    .map((item) => {
                      return item === 'monday'
                        ? this.$t('IntegrationPage.Monday').substr(0, 3)
                        : item === 'tuesday'
                        ? this.$t('IntegrationPage.Tuesday').substr(0, 3)
                        : item === 'wednesday'
                        ? this.$t('IntegrationPage.Wednesday').substr(0, 3)
                        : item === 'thursday'
                        ? this.$t('IntegrationPage.Thursday').substr(0, 3)
                        : item === 'friday'
                        ? this.$t('IntegrationPage.Friday').substr(0, 3)
                        : item === 'saturday'
                        ? this.$t('IntegrationPage.Saturday').substr(0, 3)
                        : item === 'sunday'
                        ? this.$t('IntegrationPage.Sunday').substr(0, 3)
                        : '';
                    })
                    .join(', ')
                : '-'
            }}
          </span>
        </div>
        <div class="d-flex justify-content-end item-box">
          <div class="container-btn-scheduling-item d-none d-md-block">
            <b-button
              :id="`tooltip-btn-edit-${data.id}`"
              class="btn-scheduling btn-edit"
              @click="showEditScheduling"
            >
              <span>
                <EditIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-edit-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Edit') }}
              </div>
            </b-tooltip>

            <b-button
              :id="`tooltip-btn-delete-${data.id}`"
              class="btn-scheduling btn-delete"
              @click="deleteScheduling(data.id, arrayName)"
            >
              <span>
                <DeleteIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-delete-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Delete2') }}
              </div>
            </b-tooltip>

            <b-button
              :id="`tooltip-btn-link-${data.id}`"
              class="btn-scheduling btn-close"
              @click="linkScheduling(data.id)"
              v-if="!isLinked"
            >
              <span>
                <LinkIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-link-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Link') }}
              </div>
            </b-tooltip>

            <b-button
              :id="`tooltip-btn-unlink-${data.id}`"
              class="btn-scheduling btn-close"
              @click="removeLinkedScheduling(data.id, arrayName)"
              v-if="isLinked"
            >
              <span>
                <CloseIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-unlink-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Unlink') }}
              </div>
            </b-tooltip>
          </div>
          <div class="d-block d-md-none btn-drop-scheduling">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="d-action-scheduling"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="showEditScheduling">
                  <span class="icon-action-scheduling">
                    <EditDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Edit') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteScheduling(data.id, arrayName)">
                  <span class="icon-action-scheduling">
                    <DeleteDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Delete2') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="linkScheduling(data.id)"
                  v-if="!isLinked"
                  class="d-link-sch"
                >
                  <span class="icon-action-scheduling">
                    <LinkDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Link') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="removeLinkedScheduling(data.id, arrayName)"
                  v-if="isLinked"
                >
                  <span class="icon-action-scheduling">
                    <CloseDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Unlink') }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script lang="js">
  import Ripple from 'vue-ripple-directive';
  import CloseIcon from '@/assets/images/icons/close-icon-small.svg';
  import DeleteIcon from '@/assets/images/icons/delete-icon-small.svg';
  import EditIcon from '@/assets/images/icons/edit-icon-small.svg';
  import LinkIcon from '@/assets/images/icons/link-icon.svg';
  import DeleteDropdownIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditDropdownIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import LinkDropdownIcon from '@/assets/images/icons/link-2-icon.svg';
  import CloseDropdownIcon from '@/assets/images/icons/close-2-icon.svg';
  import moment from 'moment';
  import { BRow, BTooltip, VBToggle, BButton, BDropdown, BDropdownItem, BCol } from 'bootstrap-vue';
  import { object } from '@ucast/core';

  export default {
    components: {
      BRow,
      BButton,
      BDropdown,
      BDropdownItem,
      BTooltip,
      BCol,
      moment,
      CloseIcon,
      DeleteIcon,
      EditIcon,
      LinkIcon,
      DeleteDropdownIcon,
      EditDropdownIcon,
      LinkDropdownIcon,
      CloseDropdownIcon
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        flagSchedulingList: false,
        flagNewScheduling: false
      };
    },
    props: {
      data: {
        type: object,
        default: null
      },
      isLinked: {
        type: Boolean,
        default: false
      },
      showInfos: {
        type: Function
      },
      deleteScheduling: {
        type: Function
      },
      removeLinkedScheduling: {
        type: Function
      },
      linkScheduling: {
        type: Function
      },
      arrayName: {
        type: String,
        default: ''
      }
    },
    methods: {
      showEditScheduling() {
        this.$emit('setEditingContext', this.data.id);
        this.showInfos('NewScheduling');
      },
      formatDateAndHour(v) {
        let language = localStorage.getItem('language');

        if (language == 'pt') {
          return moment(v).format('DD/MM/YYYY HH:mm:ss');
        } else if (language == 'en') {
          return moment(v).format('MM/DD/YYYY HH:mm:ss');
        }

        return v;
      }
    }
  };
</script>

<style lang="scss">
  .scheduling-item {
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 16px;
    .item-box {
      width: 20%;
      padding: 0 4px;
    }
    .item-box:nth-child(5) {
      width: 20%;
    }
    .title-item {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: #998f8a;
    }

    .text-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4c4541;
    }

    .btn-scheduling {
      background: none !important;
      border: none !important;
      padding: 0;
      width: 32px;
      height: 28px;
      margin-left: 8px;
      &:hover {
        box-shadow: none !important;
        background-color: #ffdbc4 !important;
      }
    }

    .d-action-scheduling {
      button {
        width: 32px;
        height: 28px;
        padding: 0;
        svg circle {
          color: #974900 !important;
        }
      }

      .dropdown-item {
        &:hover {
          background-color: #fbeee8;
          svg path {
            fill: #974900 !important;
          }
          span {
            color: #974900 !important;
          }
        }
      }

      button:hover {
        background-color: #ffdbc4 !important;
      }

      svg {
        margin: 0 !important;
      }

      .icon-action-scheduling {
        svg {
          height: 16px;
          width: 16px;
          path {
            fill: #998f8a;
          }
        }

        margin-right: 9px;
      }
    }
  }

  .scheduling-linked {
    background: #fafafa;
    border: 1px solid #cfc4be;
  }

  .scheduling-list {
    background: #fff;
    border: 1px solid #cfc4be;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;

    .container-btn-scheduling-item {
      transition: 1s;
      opacity: 0;
    }

    &:hover {
      .container-btn-scheduling-item {
        opacity: 1;
      }
    }
  }

  @media (max-width: 480px) {
    .scheduling-item {
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
      position: relative;

      .d-link-sch {
        display: none;
      }

      .item-box {
        width: auto !important;
      }

      .btn-drop-scheduling {
        position: absolute;
        top: 17.5px;
        right: 20.87px;
      }
    }
  }

  @media (max-width: 768px) {
    .scheduling-item {
      .item-box {
        width: 25%;
      }
      .item-box:nth-child(5) {
        width: 0%;
      }
    }
  }
</style>

<template>
  <div>
    <div
      id="container-new-pipeline"
      class="container-fluid"
    >
      <Breadcrumb :pipelineName="pipelineName" />
      <b-row>
        <span class="title-new-pipeline">
          {{ setPipelineTitle() }}
        </span>
      </b-row>

      <b-row class="row-buttons-pipeline">
        <b-col
          md="12"
          class="col-btn-pages"
        >
          <div
            :class="[
              'button-page',
              flagPipeline && 'active',
              !flagPipelineSessionValidation && flagPipelineSessionValidation != null && 'error'
            ]"
            @click="showInfos('pipeline')"
          >
            <span class="icon-row-button">
              <HierIcon />
            </span>
            <span class="title-button"> Pipeline </span>
            <span class="icon-alert-error">
              <ExclamationIcon />
            </span>
          </div>

          <div
            :class="[
              'button-page',
              flagActivity && 'active',
              disableActivity && 'disabled',
              !flagActivitySessionValidation && flagActivitySessionValidation != null && 'error'
            ]"
            @click="!disableActivity ? showInfos('activity') : ''"
          >
            <span class="icon-row-button">
              <SettingsIcon />
            </span>
            <span class="title-button">
              {{ $t('IntegrationPage.Activities') }}
            </span>
            <span class="icon-alert-error">
              <ExclamationIcon />
            </span>
          </div>

          <div
            v-if="flagAlertsSessionTab"
            :class="[
              'button-page',
              flagAlert && 'active',
              flagWaitReturnButton &&
                !flagAlertsSessionValidation &&
                flagAlertsSessionValidation != null &&
                'error'
            ]"
            @click="showInfos('alert')"
          >
            <span class="icon-row-button">
              <AlertIcon />
            </span>
            <span class="title-button">
              {{ $t('IntegrationPage.Alerts') }}
            </span>
            <span class="icon-alert-error">
              <ExclamationIcon />
            </span>
          </div>

          <div
            v-if="flagSchedulesSessionTab"
            :class="[
              'button-page',
              flagScheduling && 'active',
              !flagSchedulesSessionValidation && flagSchedulesSessionValidation != null && 'error'
            ]"
            @click="showInfos('scheduling')"
          >
            <span class="icon-row-button">
              <CalendarIcon />
            </span>
            <span class="title-button">
              {{ $t('IntegrationPage.Scheduling') }}
            </span>
            <span class="icon-alert-error">
              <ExclamationIcon />
            </span>
          </div>
        </b-col>
      </b-row>

      <!-- sessões -->

      <div v-if="flagFirstLoadingPipelineSession">
        <PipelineSession
          ref="pipelineSession"
          v-show="flagPipeline"
          :executionTypesList="executionTypesList"
          :eventList="validaListagemEvent()"
          :pipeline="pipelinePreview"
          :pipelinesList="pipelinesList"
          @disableActivity="(value) => (disableActivity = value)"
          @saveData="(value) => (pipelinePayload.pipeline = value)"
          @clean-fields-pipeline="cleanFieldsPipeline()"
          @shareSelectedExecutionType="(value) => (selectedExecutionType = value)"
        />
      </div>

      <div v-if="flagFirstLoadingActivitySession">
        <ActivitySession
          ref="activitySession"
          v-show="flagActivity"
          :actionsList="validaListagemAction()"
          :connectionsListOrigin="connectionsListOrigin"
          :connectionsListDestiny="connectionsListDestiny"
          :pipelinesList="pipelinesList"
          :pipeline="pipelinePreview"
          :selectedExecutionType="selectedExecutionType"
          :azureConnectionsList="azureConnectionsList"
          :sqlConnectionsList="sqlConnectionsList"
          @saveData="(value) => (pipelinePayload.activities = value)"
          @setDataAlertsForEditing="(value) => (editingData = value)"
        />
      </div>

      <div v-if="flagFirstLoadingAlertSession">
        <AlertSession
          ref="alertSession"
          v-show="flagAlert"
          :editingData="editingData"
          :flagAlert="flagAlert"
          @setValue="(value) => setAlertsValue(value)"
          @showActivities="showInfos('activity')"
        />
      </div>

      <div v-if="flagFirstLoadingSchedulingSession">
        <SchedulingSession
          v-show="flagScheduling"
          :pipeline="pipelinePreview"
          @saveData="(value) => (pipelinePayload.scheduling = value)"
          @changeFlagLastEmitNewPipeline="flagLastEmitNewPipeline = true"
        />
      </div>

      <div
        class="hidden-block-pipelines"
        style="height: calc(2rem + 94px); display: block"
      ></div>

      <b-button
        class="btn-float"
        @click="
          resetTabValidations();
          UPDATE_FLAG_FLOAT_SAVE_BUTTON(true);
        "
        v-show="!flagShowOverlay"
      >
        <span class="icon-btn-float">
          <SaveIcon />
        </span>
      </b-button>
    </div>
  </div>
</template>

<script lang="js">
  import Breadcrumb from '@/@core/components/breadcrumb';
  import HierIcon from '@/assets/images/icons/hierarquia-icon.svg';
  import SettingsIcon from '@/assets/images/icons/config-big-icon.svg';
  import AlertIcon from '@/assets/images/icons/alert-icon-2.svg';
  import CalendarIcon from '@/assets/images/icons/calendar-icon-2.svg';
  import ExclamationIcon from '@/assets/images/icons/exclamation-red-icon.svg';
  import SaveIcon from '@/assets/images/icons/save-icon.svg';
  import FormSelect2 from '@core/components/form-select-2/FormSelect2';
  import Ripple from 'vue-ripple-directive';
  import PipelineSession from '@/views/pages/integration/pipelines/PipelineSession.vue';
  import ActivitySession from '@/views/pages/integration/pipelines/ActivitySession.vue';
  import AlertSession from '@/views/pages/integration/pipelines/AlertSession.vue';
  import SchedulingSession from '@/views/pages/integration/pipelines/SchedulingSession.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import router from '@/router';
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import { BRow, BFormGroup, BFormInput, BCard, VBToggle, BButton, BCol } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BFormGroup,
      BFormInput,
      BCard,
      BButton,
      BCol,
      Breadcrumb,
      FormSelect2,
      PipelineSession,
      ActivitySession,
      AlertSession,
      SchedulingSession,
      HierIcon,
      SettingsIcon,
      CalendarIcon,
      AlertIcon,
      ExclamationIcon,
      SaveIcon,
      ToastificationContent
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },

    data() {
      return {
        prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        showOverlay: true,
        disableActivity: true,
        flagPipeline: true,
        flagActivity: false,
        flagAlert: false,
        flagScheduling: false,
        pipelineError: false,
        activityError: false,
        alertError: false,
        schedulingError: false,
        getPipelineLocal: false,
        executionTypesList: [],
        eventList: [],
        actionsList: [],
        actionsListBackup: [],
        connectionsListOrigin: [],
        connectionsListDestiny: [],
        connectionsListBackup: [],
        pipelinesList: [],
        azureConnectionsList: [],
        sqlConnectionsList: [],
        eventBeforeList: [],
        eventAfterList: [],
        pipelinePayload: {},
        pipelinePreview: {},
        pipelineName: '',
        flagLastEmitNewPipeline: false,
        editingData: null,
        selectedExecutionType: null,
        flagOldActivityGroup: false,
        OldValueSelectedActivityGroup: null,
        alertClean: {
          frontend_wait_reply: false,
          frontend_timeout: '',
          return_field: '',
          valid_response: '',
          message_field: '',
          typeValidAlert: null,
          custom_valid_response: '',
          typeInvalidAlert: null,
          custom_invalid_response: ''
        },
        flagFirstLoading: true,
        flagFirstLoadingPipelineSession: false,
        flagFirstLoadingAlertSession: false,
        flagFirstLoadingActivitySession: false,
        flagFirstLoadingSchedulingSession: false
      };
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagPipelineSessionValidation',
        'flagAlertsSessionValidation',
        'flagActivitySessionValidation',
        'flagSchedulesSessionValidation',
        'flagFloatSaveButton',
        'flagGetContext',
        'flagGetPipeline',
        'flagWaitReturnButton',
        'flagAlertsSessionTab',
        'flagSchedulesSessionTab',
        'selectedPerformedVia',
        'selectedEvent',
        'selectedAction',
        'canLinkAlert',
        'confirmChange',
        'linkedActivityListEmpty',
        'selectedActivityGroup',
        'flagConfirmChangeOrderLinkedActivityList',
        'flagActivityButton',
        'flagLinkedActivityListDeleted',
        'flagActivitiesIbox'
      ]),
      ...mapGetters('app', ['flagShowOverlay'])
    },
    async mounted() {
      this.$route.params.action == 'new-activity'
        ? localStorage.setItem('newActivity', 'true')
        : localStorage.removeItem('newActivity');
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      await this.renderPriorityOrder();
      this.flagFirstLoading = false;
    },
    destroyed() {
      localStorage.removeItem('newActivity');
      this.pipelineName = '';
      this.resetFlags();
    },
    watch: {
      flagPipelineSessionValidation(v) {
        if (v != null && this.flagFloatSaveButton) this.save();
      },
      flagActivitySessionValidation(v) {
        if (v != null && this.flagFloatSaveButton) this.save();
      },
      flagSchedulesSessionValidation(v) {
        if (v != null && this.flagFloatSaveButton) this.save();
      },
      flagGetPipeline(v) {
        if (v && this.flagGetContext) this.UPDATE_FLAG_SHOW_OVERLAY(false);
      },
      flagGetContext(v) {
        if (v && this.flagGetPipeline) this.UPDATE_FLAG_SHOW_OVERLAY(false);
      },
      flagShowOverlay(v) {
        if (!v) {
          this.changeActionList();
          this.changeConnectionList();
        }
      },
      pipelinePreview(v) {
        if (v) this.validaListagemEvent();
      },
      selectedPerformedVia(value, oldValue) {
        if (oldValue && (this.selectedAction || !this.linkedActivityListEmpty)) {
          if (!this.confirmChange) {
            this.$swal({
              title: this.$t('IntegrationPage.attention'),
              text: this.$t('IntegrationPage.ChangePerformedInfo'),
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonText: this.$t('MySites.cancel'),
              confirmButtonText: this.$t('IntegrationPage.Change'),
              customClass: {
                container: 'swal-conection',
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            }).then((result) => {
              if (result.value) {
                this.UPDATE_FLAG_CLEAN_FIELDS(true);
                this.$refs.alertSession.cleanFields();
                this.changeActionList();
                this.$refs.activitySession.clearlinkedActivityList();
                this.changeShowAlert();
                this.changeConnectionList();
              } else {
                this.UPDATE_CONFIRM_CHANGE(true);
                this.$refs.pipelineSession.setPerformedVia(oldValue);
              }
            });
          } else {
            this.changeActionList();
            this.changeConnectionList();
            this.changeShowAlert();
          }
        } else {
          this.changeActionList();
          this.changeConnectionList();
          this.changeShowAlert();
        }
      },
      selectedEvent(value, oldValue) {
        if (
          this.eventBeforeList.includes(oldValue) &&
          this.eventAfterList.includes(value) &&
          this.selectedPerformedVia == 1 &&
          (this.selectedAction || !this.linkedActivityListEmpty)
        ) {
          if (!this.confirmChange) {
            this.$swal({
              title: this.$t('IntegrationPage.attention'),
              text: this.$t('IntegrationPage.ChangeEventInfo'),
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonText: this.$t('MySites.cancel'),
              confirmButtonText: this.$t('IntegrationPage.Change'),
              customClass: {
                container: 'swal-conection',
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            }).then((result) => {
              if (result.value) {
                this.$refs.alertSession.cleanFields();
                this.changeShowAlert();
                this.changeConnectionList();
              } else {
                this.UPDATE_CONFIRM_CHANGE(true);
                this.$refs.pipelineSession.setEvent(oldValue);
              }
            });
          } else this.changeShowAlert();
          this.changeConnectionList();
        } else {
          this.changeShowAlert();
          this.changeConnectionList();
        }
      },
      selectedAction(v) {
        const firstGroup =
          this.$refs.activitySession.linkedActivityList.length > 0
            ? this.$refs.activitySession.linkedActivityList[0]
            : null;
        const groupListLength = this.$refs.activitySession.linkedActivityList?.length;
        const isEditingActivity = this.$refs.activitySession.flagEditActivityFixed;
        const editingDataGroup = this.$refs.activitySession.$refs.newActivity.editingData;

        if (
          (!isEditingActivity && groupListLength == 0) ||
          (isEditingActivity &&
            editingDataGroup.activityGroup?.groupName == firstGroup?.groupName &&
            firstGroup?.activities.length == 1 &&
            (firstGroup?.activities[0].infos.action == 1 ||
              firstGroup?.activities[0].infos.action == 2))
        ) {
          this.changeShowAlert();
        }
      },
      canLinkAlert(v) {
        this.changeShowAlert();
      },
      selectedActivityGroup(newValue, oldValue) {
        if (this.$refs.activitySession.flagNewActivityIbox) {
          if (
            !this.flagOldActivityGroup &&
            this.$refs.activitySession.linkedActivityList?.length > 0 &&
            this.eventBeforeList.includes(this.selectedEvent) &&
            this.selectedPerformedVia == 1
          ) {
            const groupListLength = this.$refs.activitySession.linkedActivityList?.length;
            const newGroupSelected = this.$refs.activitySession.linkedActivityList?.find(
              (group) => group?.groupName == newValue?.label
            );
            const oldGroupSelectedIndex = this.$refs.activitySession.linkedActivityList?.findIndex(
              (group) => group?.groupName == oldValue?.label
            );
            const newGroupSelectedIndex = this.$refs.activitySession.linkedActivityList?.findIndex(
              (group) => group?.groupName == newValue?.label
            );
            const firstGroup =
              this.$refs.activitySession.linkedActivityList.length > 0
                ? this.$refs.activitySession.linkedActivityList[0]
                : null;
            const hasSingleActivityAndRightAction =
              newGroupSelected?.activities.length == 1 &&
              (newGroupSelected?.activities[0].infos.action == 1 ||
                newGroupSelected?.activities[0].infos.action == 2);
            const hasSingleActivityAndRightActionInFirstGroup =
              firstGroup.activities.length == 1 &&
              (firstGroup?.activities[0].infos.action == 1 ||
                firstGroup?.activities[0].infos.action == 2);
            const isEditingActivity = this.$refs.activitySession.flagEditActivityFixed;
            const editingDataGroup = this.$refs.activitySession.$refs.newActivity.editingData;

            if (
              (newGroupSelectedIndex == 0 &&
                hasSingleActivityAndRightAction &&
                !isEditingActivity) ||
              (editingDataGroup.activityGroup?.groupName == firstGroup?.groupName &&
                groupListLength > 1 &&
                newGroupSelectedIndex !== 0 &&
                hasSingleActivityAndRightActionInFirstGroup &&
                oldGroupSelectedIndex === 0 &&
                isEditingActivity &&
                oldValue != null &&
                editingDataGroup.activityGroup?.groupName != newGroupSelected?.groupName) ||
              (editingDataGroup.activityGroup?.groupName != firstGroup?.groupName &&
                groupListLength > 1 &&
                newGroupSelectedIndex === 0 &&
                hasSingleActivityAndRightAction &&
                isEditingActivity &&
                oldValue != null &&
                editingDataGroup.activityGroup?.groupName != newGroupSelected?.groupName)
            ) {
              this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('IntegrationPage.AddActivityInGroupWithAlertsInfo'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('IntegrationPage.Change'),
                customClass: {
                  container: 'swal-conection',
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
              }).then((result) => {
                if (result.value) {
                  this.$refs.activitySession.linkedActivityList.forEach((group) =>
                    group.activities.forEach((activity) => {
                      activity.alerts = this.alertClean;
                    })
                  );
                  this.$refs.alertSession.cleanFields();
                  if (this.flagAlertsSessionTab) this.changeShowAlert();
                } else {
                  this.flagOldActivityGroup = true;
                  this.UPDATE_OLD_VALUE_SELECTED_ACTIVITY_GROUP(oldValue);
                }
              });
            }
          } else {
            this.UPDATE_OLD_VALUE_SELECTED_ACTIVITY_GROUP(false);
            this.flagOldActivityGroup = false;
          }
        }
      },
      flagActivitiesIbox(v) {
        if (v && this.flagAlertsSessionTab) this.UPDATE_FLAG_ALERTS_SESSION(false);
      },
      flagConfirmChangeOrderLinkedActivityList(v) {
        if (v) {
          this.$swal({
            title: this.$t('IntegrationPage.attention'),
            text: this.$t('IntegrationPage.ChangeActivityGroupSequence'),
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonText: this.$t('MySites.cancel'),
            confirmButtonText: this.$t('IntegrationPage.Change'),
            customClass: {
              container: 'swal-conection',
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              this.$refs.activitySession.linkedActivityList.forEach((group) =>
                group.activities.forEach((activity) => {
                  activity.alerts = this.alertClean;
                })
              );
              this.$refs.alertSession.cleanFields();
              this.changeShowAlert();
            } else {
              return this.UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST(false);
            }
            return this.UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST(null);
          });
        }
      },
      flagLinkedActivityListDeleted(v) {
        if (v) {
          this.$refs.activitySession.linkedActivityList.forEach((group) =>
            group.activities.forEach((activity) => {
              activity.alerts = this.alertClean;
            })
          );
          this.$refs.alertSession.cleanFields();
          this.UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED(null);
        }
      }
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', [
        'UPDATE_FLAG_PIPELINE_SESSION_VALIDATION',
        'UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION',
        'UPDATE_FLAG_ALERTS_SESSION_VALIDATION',
        'UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION',
        'UPDATE_FLAG_FLOAT_SAVE_BUTTON',
        'UPDATE_HAS_CONTENT_EVENT_LIST',
        'UPDATE_HAS_CONTENT_EXECUTION_TYPE_LIST',
        'UPDATE_FLAG_GET_CONTEXT',
        'UPDATE_FLAG_GET_PIPELINE',
        'UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED',
        'UPDATE_FLAG_ALERTS_SESSION',
        'UPDATE_FLAG_SCHEDULES_SESSION',
        'UPDATE_CONFIRM_CHANGE',
        'UPDATE_FLAG_CLEAN_FIELDS',
        'UPDATE_SELECTED_ACTIVITY_GROUP',
        'UPDATE_OLD_VALUE_SELECTED_ACTIVITY_GROUP',
        'UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST',
        'UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED'
      ]),
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapActions('flags_controller_pipelines', ['resetTabValidations', 'resetFlags']),
      getPipeline() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        this.$http
          .get(`/api/${this.prefix}/event_script/pipelines/${this.$route.params.id}`)
          .then((res) => {
            let response = res.data[0];
            response.pipeline_group.forEach((item1) => {
              item1.events_scripts.forEach((item2) => {
                item2.config = JSON.parse(item2.config);
              });
            });

            this.pipelineName = response.name;
            this.pipelinePreview = {
              pipeline: {
                name: response.name,
                execution_type_id: response.execution_type_id,
                event_id: response.event_id
              },
              pipeline_group: response.pipeline_group?.map((el1, i) => {
                return {
                  id: el1.id,
                  groupName: el1.name,
                  order: i,
                  if_success: el1.next_sequence_if_success,
                  if_error: el1.next_sequence_if_error,
                  activities: el1.events_scripts?.map((el2) => {
                    let script = {
                      id: el2.id,
                      infos: {
                        activityName: el2.name,
                        action: el2.script_action_id
                      },
                      alerts: {
                        frontend_wait_reply: el2.frontend_wait_reply,
                        frontend_timeout: el2.frontend_timeout,
                        return_field: el2.return_field,
                        valid_response: el2.valid_response,
                        message_field: el2.return_message_field,
                        custom_valid_response: el2.custom_valid_message,
                        custom_invalid_response: el2.custom_invalid_message,
                        typeValidAlert: el2.type_valid_alert,
                        typeInvalidAlert: el2.type_invalid_alert
                      }
                    };

                    if (el2.script_action_id == 1) {
                      let runStoredProcedure = {};

                      if (el2.config?.connectionId)
                        runStoredProcedure.connectionId = el2.config?.connectionId;
                      if (el2.config?.procedureName)
                        runStoredProcedure.procedureName = el2.config?.procedureName;

                      if (runStoredProcedure) script.runStoredProcedure = runStoredProcedure;
                    } else if (el2.script_action_id == 2) {
                      if (el2.config.connection) script.integrations = el2.config.connection;
                      if (el2.config.mapping) script.fieldsMapping = el2.config.mapping;
                      if (el2.config.sap_return_fields)
                        script.fieldsReturn = el2.config.sap_return_fields;
                      if (el2.config.keys) script.identificationKeys = el2.config.keys;
                      if (el2.config.behavior) script.routinesBehavior = el2.config.behavior;
                      if (el2.config.batch_quantity) {
                        script.batchQuantity = {};
                        script.batchQuantity.batchQuantity = el2.config.batch_quantity;
                      }
                      if (el2.config?.procedures)
                        script.procedures = el2.config.procedures?.map((item) => {
                          return { id: item.name, ...item };
                        });
                    } else if (el2.script_action_id == 3) {
                      let azureDataFactory = {};

                      if (el2.config?.connectionId)
                        azureDataFactory.connectionId = el2.config?.connectionId;
                      if (el2.config?.pipelineId)
                        azureDataFactory.pipelineId = el2.config?.pipelineId;

                      if (azureDataFactory) script.azureDataFactory = azureDataFactory;
                    } else if (el2.script_action_id == 4) {
                      let runPowerShell = {};

                      if (el2.config?.scriptPowerShell)
                        runPowerShell.scriptPowerShell = el2.config?.scriptPowerShell;

                      if (runPowerShell) script.runPowerShell = runPowerShell;
                    } else if (el2.script_action_id == 5) {
                      let runPipeline = {};

                      if (el2.config?.pipelineId) runPipeline.pipelineId = el2.config?.pipelineId;

                      if (runPipeline) script.runPipeline = runPipeline;
                    } else if (el2.script_action_id == 6) {
                      let emailServer = {};

                      if (el2.config?.to?.length > 0) emailServer.to = el2.config?.to;
                      if (el2.config?.pipelines) emailServer.pipelines = el2.config.pipelines;
                      if (el2.config?.email_server)
                        emailServer.email_server = el2.config?.email_server;

                      if (emailServer) script.emailServer = emailServer;
                    }

                    return script;
                  })
                };
              }),
              schedules: response.schedules.map((item) => {
                return {
                  id: item.id,
                  event: item.name,
                  startDate: item.start_date?.split('.')[0],
                  recurrenceNumber: item.units_to_add,
                  recurrencePeriod: item.periodicity,
                  days: item.weekDays
                };
              }),
              schedules_no_linked: response.schedules_no_linked?.map((item) => {
                return {
                  id: item.id,
                  event: item.name,
                  startDate: item.start_date?.split('.')[0],
                  recurrenceNumber: item.units_to_add,
                  recurrencePeriod: item.periodicity,
                  days: item.weekDays
                };
              })
            };

            if (!this.flagFirstLoading) this.UPDATE_FLAG_GET_PIPELINE(true);
            this.getPipelineLocal = true;
          })
          .catch(() => {
            this.getPipelineLocal = false;
          });
      },
      validaListagemEvent() {
        let language = localStorage.getItem('language');

        if (language == 'pt') {
          return this.eventList.map((item) => {
            return {
              group: item.group_pt,
              groupLib: item.options.map((element) => {
                return {
                  text: element.text_pt,
                  value: element.value,
                  $isDisabled:
                    this.pipelinePreview?.pipeline?.event_id != element.value &&
                    element.active == 1 &&
                    true
                };
              })
            };
          });
        } else if (language == 'en') {
          return this.eventList.map((item) => {
            return {
              group: item.group_en,
              groupLib: item.options.map((element) => {
                return {
                  text: element.text_en,
                  value: element.value,
                  $isDisabled:
                    this.pipelinePreview?.pipeline?.event_id != element.value &&
                    element.active == 1 &&
                    true
                };
              })
            };
          });
        }
      },
      validaListagemAction() {
        let language = localStorage.getItem('language');

        if (language == 'pt') {
          return this.actionsList
            .map((item) => {
              return { text: item.text_pt, value: item.value };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
        } else if (language == 'en') {
          return this.actionsList
            .map((item) => {
              return { text: item.text_en, value: item.value };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
        }
      },
      async renderPriorityOrder() {
        await new Promise(async (resolveRender) => {
          this.getContextAndPipeline();
          await new Promise((resolvePriority) => {
            setTimeout(() => (this.flagFirstLoadingPipelineSession = true), 100);
            setTimeout(() => (this.flagFirstLoadingSchedulingSession = true), 600);
            setTimeout(() => {
              this.flagFirstLoadingActivitySession = true;
              resolvePriority();
            }, 1200);
          });
          this.flagFirstLoadingAlertSession = true;
          this.getPipelineLocal && this.UPDATE_FLAG_GET_PIPELINE(true);
          this.redirectByParam();
          resolveRender();
        });
      },
      contextoPipelines() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.eventList = [];
        this.eventBeforeList = [];
        this.eventAfterList = [];

        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/context/${this.currentSite.guid}`
          )
          .then((res) => {
            this.executionTypesList = res.data.executionTypes.map((item) => {
              return { value: item.id, text: item.name };
            });
            this.executionTypesList.sort((a, b) => a.text.localeCompare(b.text));
            let actionfilter = res.data.actions.filter(
              (item) =>
                item.id == 1 ||
                item.id == 2 ||
                item.id == 3 ||
                item.id == 4 ||
                item.id == 5 ||
                item.id == 6
            );

            this.actionsListBackup = actionfilter.map((item) => {
              return { value: item.id, text_pt: item.name_pt, text_en: item.name_en };
            });
            this.actionsList = actionfilter.map((item) => {
              return { value: item.id, text_pt: item.name_pt, text_en: item.name_en };
            });

            let connections = res.data.connections
              .map((item) => {
                return {
                  value: item.id,
                  text: item.name,
                  type: item.type_id,
                  objects: item.objects.sort((a, b) => a?.name.localeCompare(b?.name))
                };
              })
              .sort((a, b) => a.text.localeCompare(b.text));
            this.connectionsListBackup = [...connections];
            this.azureConnectionsList = [
              ...connections
                .filter((item) => item.type == 6)
                .map((item) => {
                  return {
                    ...item,
                    objects: item.objects.map((object) => {
                      return { value: object.id, text: object.name };
                    })
                  };
                })
            ];
            this.sqlConnectionsList = [
              ...connections
                .filter((item) => item.type == 2)
                .map((item) => {
                  return {
                    ...item,
                    objects: item.objects.map((object) => {
                      return { value: object.id, text: object.name };
                    })
                  };
                })
            ];
            this.connectionsListOrigin = [...connections];
            this.connectionsListDestiny = [...connections];

            res.data.events.map((item) => {
              if (this.eventList.find((element) => element.group_en == item.type_en)) {
                let index = this.eventList.findIndex((element) => element.group_en == item.type_en);
                this.eventList[index].options.push({
                  text_pt: item.name_pt,
                  text_en: item.name_en,
                  value: item.id,
                  active: item.active
                });
              } else if (this.eventList.find((element) => element.group_pt == item.type_pt)) {
                let index = this.eventList.findIndex((element) => element.group_pt == item.type_pt);
                this.eventList[index].options.push({
                  text_pt: item.name_pt,
                  text_en: item.name_en,
                  value: item.id,
                  active: item.active
                });
              } else {
                this.eventList.push({
                  group_pt: item.type_pt,
                  group_en: item.type_en,
                  options: [
                    {
                      text_pt: item.name_pt,
                      text_en: item.name_en,
                      value: item.id,
                      active: item.active
                    }
                  ]
                });
              }

              if (item.name_pt.includes('Antes')) {
                this.eventBeforeList.push(item.id);
              } else if (item.name_pt.includes('Após')) {
                this.eventAfterList.push(item.id);
              }

              return this.eventList;
            });

            this.pipelinesList = res.data.pipelines.map((item) => {
              return {
                value: item.id,
                label: item.name,
                text: item.name,
                executionType: item.execution_type_id,
                eventId: item.event_id
              };
            });

            if (!this.$route.params.action) this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.UPDATE_HAS_CONTENT_EVENT_LIST(true);
            this.UPDATE_HAS_CONTENT_EXECUTION_TYPE_LIST(true);
            this.UPDATE_FLAG_GET_CONTEXT(true);
          });
      },
      showInfos(type) {
        this.flagPipeline = false;
        this.flagActivity = false;
        this.flagAlert = false;
        this.flagScheduling = false;

        if (type !== 'activity' && localStorage.getItem('newActivity') === 'true') {
          localStorage.setItem('newActivity', 'false');
        }

        if (type == 'pipeline') {
          this.flagPipeline = true;
        } else if (type == 'activity') {
          this.flagActivity = true;
        } else if (type == 'alert') {
          this.flagAlert = true;
        } else if (type == 'scheduling') {
          this.flagScheduling = true;
        }
      },
      validateTabs() {
        if (
          this.flagPipelineSessionValidation != null &&
          this.flagActivitySessionValidation != null &&
          this.flagSchedulesSessionValidation != null
        ) {
          return (
            this.flagPipelineSessionValidation &&
            this.flagActivitySessionValidation &&
            this.flagSchedulesSessionValidation
          );
        }

        return null;
      },
      save() {
        let validate = this.validateTabs();
        if (validate == null) return;
        this.UPDATE_FLAG_FLOAT_SAVE_BUTTON(false);

        if (validate) {
          let payload = {
            pipeline: this.pipelinePayload?.pipeline,
            pipeline_groups: this.pipelinePayload?.activities?.map((el1) => {
              return {
                name: el1.groupName,
                if_success: el1.if_success,
                if_error: el1.if_error,
                events_scripts: el1.activities?.map((el2) => {
                  let script = {
                    config: {}
                  };

                  if (el2.infos.activityName) script.name = el2.infos?.activityName;
                  if (el2.infos.action) script.script_action_id = el2.infos?.action;

                  //alerts
                  script.frontend_wait_reply = el2.alerts?.frontend_wait_reply;
                  script.frontend_timeout = el2.alerts?.frontend_timeout;
                  script.return_field = el2.alerts?.return_field;
                  script.valid_response = el2.alerts?.valid_response;
                  script.return_message_field = el2.alerts?.message_field;
                  script.custom_valid_response = el2.alerts?.custom_valid_response;
                  script.custom_invalid_response = el2.alerts?.custom_invalid_response;
                  script.type_valid_alert = el2.alerts?.typeValidAlert;
                  script.type_invalid_alert = el2.alerts?.typeInvalidAlert;

                  if (el2.infos.action == 1) {
                    if (el2.runStoredProcedure) script.config = el2.runStoredProcedure;
                  } else if (el2.infos.action == 2) {
                    if (el2.integrations) script.config.connection = el2.integrations;
                    if (el2.fieldsMapping) script.config.mapping = el2.fieldsMapping;
                    if (el2.fieldsReturn) script.config.sap_return_fields = el2.fieldsReturn;
                    if (el2.identificationKeys) script.config.keys = el2.identificationKeys;
                    if (el2.routinesBehavior) script.config.behavior = el2.routinesBehavior;
                    if (el2.batchQuantity?.batchQuantity)
                      script.config.batch_quantity = el2.batchQuantity?.batchQuantity;
                    if (el2.procedures) {
                      if (el2.procedures.length > 0) {
                        script.config.procedures = el2.procedures?.map((el3) => {
                          return {
                            name: el3.name,
                            local: el3.local,
                            type: el3.type
                          };
                        });
                      } else if (el2.procedures?.length == 0) {
                        script.config.procedures = [];
                      }
                    }
                  } else if (el2.infos.action == 3) {
                    if (el2.azureDataFactory) script.config = el2.azureDataFactory;
                  } else if (el2.infos.action == 4) {
                    if (el2.runPowerShell) script.config = el2.runPowerShell;
                  } else if (el2.infos.action == 5) {
                    if (el2.runPipeline) script.config = el2.runPipeline;
                  } else if (el2.infos.action == 6) {
                    if (el2.emailServer) script.config = el2.emailServer;
                  }

                  return script;
                })
              };
            }),
            schedules: this.pipelinePayload?.scheduling
              ? this.pipelinePayload?.scheduling?.map((el) => {
                  return {
                    id: el.id.includes('sch') ? null : el.id,
                    name: el.event,
                    start_date: el.startDate,
                    periodicity: el.recurrencePeriod?.toString(),
                    units_to_add: el.recurrenceNumber,
                    weekDays: el.days
                  };
                })
              : []
          };

          if (payload.pipeline?.event_id == null) delete payload.pipeline?.event_id;

          if (!payload.pipeline_groups || payload.pipeline_groups.length == 0) return;

          this.UPDATE_FLAG_SHOW_OVERLAY(true);

          if (this.$route.params.action) {
            this.$http
              .put(
                `/api/${this.prefix}/event_script/pipelines/${this.currentSite.guid}/${this.$route.params.id}`,
                payload
              )
              .then(() => {
                this.UPDATE_FLAG_GET_CONTEXT(false);
                this.UPDATE_FLAG_GET_PIPELINE(false);
                this.getContextAndPipeline();
              })
              .catch((e) => {
                console.error(e);
                if (e.response.status == 400) {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    this.$t('IntegrationPage.MessageErrorSavePipeline'),
                    'danger'
                  );
                  this.UPDATE_FLAG_PIPELINE_SESSION_VALIDATION(false);
                  this.UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED(true);
                  this.contextoPipelines();
                }
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
              });
          } else {
            this.$http
              .post(`/api/${this.prefix}/event_script/pipelines/${this.currentSite.guid}`, payload)
              .then(() => {
                this.UPDATE_FLAG_GET_CONTEXT(false);
                this.UPDATE_FLAG_GET_PIPELINE(false);
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('IntegrationPage.MessagePipelineCreated'),
                  'success'
                );

                this.$http
                  .get(
                    `/api/${this.$router.currentRoute.params.prefix}/event_script/pipelines/list?site_guid=${this.currentSite.guid}`
                  )
                  .then((res) => {
                    router.push({
                      name: 'new-pipeline',
                      params: {
                        action: 'edit',
                        id: res.data.find(
                          (item) => item.name == this.pipelinePayload?.pipeline.name
                        ).id
                      }
                    });
                    this.getContextAndPipeline();
                  });
              })
              .catch((e) => {
                console.error(e);
                if (e.response.status == 400) {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    this.$t('IntegrationPage.MessageErrorSavePipeline'),
                    'danger'
                  );
                  this.UPDATE_FLAG_PIPELINE_SESSION_VALIDATION(false);
                  this.UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED(true);
                  this.contextoPipelines();
                }
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
              });
          }
        } else {
          this.pipelinePayload = {};
          this.showToast(
            this.$t('IntegrationPage.Error'),
            'SmileIcon',
            this.$t('IntegrationPage.MessageErrorSavePipeline'),
            'danger'
          );
        }
      },
      getContextAndPipeline() {
        this.contextoPipelines();
        this.$route.params.action && this.getPipeline();
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      redirectByParam() {
        if (this.$route.params.action == 'to-schedule') {
          this.showInfos('scheduling');
        } else if (this.$route.params.action == 'new-activity') {
          this.showInfos('activity');
        } else {
          this.showInfos('pipeline');
        }
      },
      changeShowAlert() {
        let group = this.$refs.activitySession.linkedActivityList?.find(
          (group) =>
            group?.groupName ==
            this.$refs.activitySession.$refs.newActivity.selectedActivityGroup?.label
        );
        let groupIndex = this.$refs.activitySession.linkedActivityList?.findIndex(
          (group) =>
            group?.groupName ==
            this.$refs.activitySession.$refs.newActivity.selectedActivityGroup?.label
        );

        if (
          this.selectedPerformedVia == 1 &&
          this.selectedEvent >= 1 &&
          this.selectedEvent <= 6 &&
          (this.selectedAction == 2 || this.selectedAction == 1) &&
          ((groupIndex == 0 &&
            group?.activities.length == 1 &&
            (group?.activities[0].infos.action == 1 || group.activities[0].infos.action == 2)) ||
            group == undefined)
        ) {
          this.UPDATE_FLAG_ALERTS_SESSION(true);
        } else {
          this.UPDATE_FLAG_ALERTS_SESSION(false);
        }
      },
      changeActionList() {
        // Dicionário do value
        // 1 - Executar Stored Procedure, 2 - Executar Integração, 3 - Executar Azure Pipeline,
        // 4 - Executar PowerShell, 5 - Executar Pipeline, 6 - Enviar Relatório por E-mail

        if (this.selectedPerformedVia == 1) {
          this.actionsList = this.actionsListBackup.filter((f) => {
            if (f.value == 1 || f.value == 2) return f;
          });

          this.UPDATE_FLAG_SCHEDULES_SESSION(false);
        } else {
          this.actionsList = this.actionsListBackup;
          this.UPDATE_FLAG_SCHEDULES_SESSION(true);
        }
      },
      setAlertsValue(object) {
        this.$refs.activitySession.setAlerts(object);
      },
      changeConnectionList() {
        if (this.selectedPerformedVia == 2 && !this.selectedEvent) {
          this.connectionsListOrigin = this.connectionsListBackup.filter(
            (f) => f.type != 1 && f.type != 6
          );
          this.connectionsListDestiny = this.connectionsListBackup.filter(
            (f) => f.type != 1 && f.type != 6
          );
        } else if (this.selectedPerformedVia == 2 && this.selectedEvent) {
          this.connectionsListOrigin = this.connectionsListBackup.filter((f) => f.type != 6);
          this.connectionsListDestiny = this.connectionsListBackup.filter(
            (f) => f.type != 1 && f.type != 6
          );
        } else if (this.selectedPerformedVia == 1) {
          this.connectionsListOrigin = this.connectionsListBackup.filter(
            (f) => f.type == 1 || f.type == 2
          );
          this.connectionsListDestiny = this.connectionsListBackup.filter((f) => f.type == 5);
        }
      },
      setPipelineTitle() {
        return this.$route?.params?.action
          ? this.pipelineName
          : this.$t('IntegrationPage.NewPipeline');
      }
    }
  };
</script>

<style lang="scss">
  #container-new-pipeline {
    .card {
      overflow-x: visible !important;
    }

    .breadcrumbs-top {
      flex-direction: column !important;

      & > div {
        max-width: none !important;
      }

      ol {
        padding-left: 0 !important;
      }

      .breadcrumb-wrapper {
        margin-bottom: 0 !important;
      }
    }

    .is-invalid {
      background: none;
    }

    .custom-select {
      background: #fff url('../../../../assets/images/pages/arrow-down-2.png') right 1rem
        center/10px 10px no-repeat;
    }

    .vs__search {
      border: 1px solid transparent !important;
    }

    .title-new-pipeline {
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      color: #4c4541;
      padding: 0 0 16px 14px;
    }

    .row-buttons-pipeline {
      width: 100%;
      overflow: scroll;
      padding: 0 0 16px 14px;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .col-btn-pages {
        min-width: 715px;
        display: flex;
        padding-bottom: 4px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;

        .button-page {
          background-color: transparent !important;
          display: flex;
          align-items: center;
          border: 0px !important;
          border-radius: 6px;
          margin-right: 16px;
          span {
            color: #998f8a !important;
          }

          .title-button {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            padding: 10px 16px 10px 10px;
          }

          .icon-row-button {
            padding-left: 16px;
            margin-top: -3px;
          }

          .icon-alert-error {
            padding-right: 22.78px;
            display: none;
            margin-top: -3px;
          }

          &:hover {
            cursor: pointer;
          }

          &.active {
            background-color: #974900 !important;

            span {
              color: #ffff !important;
            }

            svg path {
              fill: #fff !important;
            }
          }

          &.error {
            border: 1px solid #d32f2f !important;

            span {
              color: #d32f2f !important;
            }

            svg path {
              fill: #d32f2f !important;
            }

            .icon-alert-error {
              display: block;
            }
          }

          &.error.active {
            background-color: #d32f2f !important;

            span {
              color: #fff !important;
            }

            svg path {
              fill: #fff !important;
            }
          }

          &.disabled {
            background-color: #ece0db !important;

            span {
              color: #7e7570 !important;
            }

            svg path {
              fill: #7e7570 !important;
            }
          }
        }
      }
    }

    .btn-float {
      display: flex;
      position: fixed;
      padding: 0 !important;
      bottom: 30px;
      right: 29px;
      z-index: 99;
      background-color: #974900 !important;
      border: none !important;
      outline: none;
      box-shadow:
        0px 3px 4px rgba(0, 0, 0, 0.14),
        0px 3px 3px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      &:active {
        background-color: #a45f1f !important;
      }

      &:hover {
        box-shadow:
          0px 3px 4px rgba(0, 0, 0, 0.14),
          0px 3px 3px rgba(0, 0, 0, 0.12),
          0px 1px 8px rgba(0, 0, 0, 0.2) !important;
        background: #9f5714 !important;
      }

      .icon-btn-float {
        padding: 20.5px;
      }
    }

    .hidden-block-pipelines {
      opacity: 0 !important;
      height: 50px !important;
      display: block;
    }
  }

  @media (max-width: 480px) {
    .info-tooltip-new-pipeline {
      max-width: 250px !important;
    }

    #container-new-pipeline {
      .title-new-pipeline {
        display: none;
      }

      .hidden-block-pipelines {
        height: 30px !important;
      }
    }
  }

  @media (max-width: 768px) {
    .info-tooltip-new-pipeline {
      max-width: 180px !important;
    }
  }

  .swal-conection {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
        }
      }

      .swal2-content {
        padding: 20px 16px;
        text-align: left;

        .swal2-html-container {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }

  .tooltip-new-pipeline {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;
    .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-new-pipeline {
        max-width: 371px;
        padding: 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .arrow {
      &::before {
        border-top-color: #323232;
        border-bottom-color: #323232;
      }
    }
  }
</style>
